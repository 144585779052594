//palette for FatBet Casino
$bg-color: #14395f;
$text-color: #fff;
$primary: #f5b21a;
$success: #28a745;
$info: #cdad8d;
$danger: #dc3545;


$gray-700: mix($bg-color, #fff, 80%); //bs-border-color


#rivalHeaderNavAndLogoSection img {
  margin-top: -10px;
  margin-bottom: -10px;
  max-width: 200px !important;
}



$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
  .dropdown-item:active {
    background-color: $primary !important;
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}


